export const plusZero = (num) => {
  return num < 10 ? `0${num}` : num
}

//= > Number 轉換為時間 HH:MM
export const timesConvert = (number) => {
  number = number / 60
  const hour = Math.floor(number)
  const decpart = number - hour
  const minute = Math.ceil((decpart * 60).toFixed(1))
  const time = `${plusZero(hour)}:${plusZero(minute)}`

  return time
}

export const timeToNumber = (time) => {
  const [hour, minute] = time.split(':')
  return parseInt(hour) * 60 + parseInt(minute)
}

// 生成時段 (format)
export const generateFormatPeriod = ({ gap, offset, maxEnd }) => {
  const times = []
  const end = maxEnd || 1440
  let time = 0
  if (offset) time += offset
  while (time <= end) {
    times.push(timesConvert(time))
    time += gap
  }

  return times
}
