<template>
  <div class="side-highlight-container">
    <p class="block-title">發票資訊</p>
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="選擇發票類型" prop="invoiceType">
        <BaseElSelect v-model="formData.invoiceType">
          <BaseElSelectOption
            v-for="option in [
              { value: 'C', label: '二聯式發票' },
              { value: 'B', label: '三聯式發票' },
            ]"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <div v-if="formData.invoiceType === 'B'">
        <BaseElFormItem label="公司行號抬頭" prop="companyTitle">
          <BaseElInput v-model="formData.companyTitle" placeholder="請輸入公司行號抬頭" clearable />
        </BaseElFormItem>
        <BaseElFormItem label="統一編號" prop="taxId">
          <BaseElInput v-model="formData.taxId" placeholder="請輸入統一編號" clearable />
        </BaseElFormItem>
      </div>

      <BaseElFormItem label="電子信箱" prop="email">
        <BaseElInput v-model="formData.email" placeholder="請輸入Email" clearable />
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { emailRules, noEmptyRules, isDigitRules, lengthRules } from '@/validation'
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { get } from 'lodash'
export default defineComponent({
  name: 'InvoiceBlock',
  props: {
    memberData: { type: Object, default: () => ({}) },
  },
  emits: ['update', 'ref'],
  setup(props, { emit }) {
    const formRef = ref(null)
    const formData = reactive({
      invoiceType: null,
      companyTitle: null,
      taxId: null,
      email: null,
    })

    const formRules = computed(() => {
      return {
        invoiceType: [noEmptyRules()],
        companyTitle: [noEmptyRules()],
        taxId: [noEmptyRules(), lengthRules(8, 8), isDigitRules()],
        email: [noEmptyRules(), emailRules()],
      }
    })

    watch(
      formData,
      () => {
        emit('update', formData)
      },
      { deep: true },
    )

    watch(
      () => props.memberData,
      (newVal) => {
        console.log('memberData', newVal)
        formData.email = get(newVal, 'member.UserInfo.email')
      },
      { deep: true, immediate: true },
    )

    onMounted(async () => {
      await nextTick()
      emit('ref', formRef.value)
    })

    return { formRef, formData, formRules }
  },
})
</script>

<style lang="postcss" scoped></style>
