<template>
  <div v-if="service && service.enableClassTicket" class="select-service-classTicket">
    <p class="title">{{ title }}</p>
    <p class="service-name">{{ service.name }}</p>
    <hr style="margin: 24px 0">
    <BaseElFormItem :prop="propName">
      <template slot="label">
        <FormItemTooltipLabel label="選擇堂票" :tooltipWidth="200">
          <p>僅顯示此會員擁有、並能用於已選擇的服務項目的堂票</p>
        </FormItemTooltipLabel>
      </template>
      <BaseElSelect
        v-model="selectedClassTicket"
        v-loading="loading"
        testName="formData_service_classTicket"
        style="width: 100%"
        value-key="id"
        :clearable="service.requiredClassTicket ? false : true"
        @clear="selectedClassTicket = null"
      >
        <BaseElSelectOption
          v-for="classTicket in classTicketOptions"
          :key="classTicket.id"
          :label="classTicket.name"
          :value="classTicket"
        />
      </BaseElSelect>
    </BaseElFormItem>
  </div>
</template>

<script>
import { defineComponent, onMounted, watch, ref, computed, nextTick } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useReservationClassTicket } from '@/use/useReservationClassTicket'
import { useVModel } from '@vueuse/core'
import { get, filter, includes } from 'lodash'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'SelectServiceClassTicket',
  components: { FormItemTooltipLabel },
  props: {
    title: String,
    propName: String,
    service: { type: Object, default: () => ({}) },
    memberClassTickets: { type: Array, default: () => ([]) },
    classTicket: { type: Object, default: () => ({}) },
    reservationTime: [Date, String],
    required: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    const {
      defaultUseServiceClassTicket,
      filterMemberAvailableServiceClassTickets,
      checkClassTicketForReservationTime,
      sortClassTickets,
    } = useReservationClassTicket()
    const selectedClassTicket = useVModel(props, 'classTicket', emit)
    // const itemRules = computed(() => {
    //   if (props.required) return [noEmptyRules()]
    //   return []
    // })
    const loading = ref(true)

    const classTicketOptions = computed(() => {
      const availableTickets = filterMemberAvailableServiceClassTickets(props.service, props.memberClassTickets)

      const tickets = filter(props.memberClassTickets, (ticketRecord) => {
        const available = includes(availableTickets, ticketRecord.ClassTicketId)
        if (!available) return false
        return checkClassTicketForReservationTime(props.reservationTime, ticketRecord)
      })

      return filter(sortClassTickets(tickets), (ticket) => ticket.availableUseTimes > 0 && ticket.status === 'open')
    })

    watch(() => props.service, async () => {
      loading.value = true
      await nextTick()
      setTimeout(() => {
        const defaultTicket = defaultUseServiceClassTicket(props.service, props.reservationTime, props.memberClassTickets)
        selectedClassTicket.value = defaultTicket
        loading.value = false
      }, 500)
    })

    watch(() => props.memberClassTickets, async () => {
      loading.value = true
      await nextTick()
      setTimeout(() => {
        const defaultTicket = defaultUseServiceClassTicket(props.service, props.reservationTime, props.memberClassTickets)
        selectedClassTicket.value = defaultTicket
        loading.value = false
      }, 500)
    })

    onMounted(async () => {
      await nextTick()
      setTimeout(() => {
        const defaultTicket = defaultUseServiceClassTicket(props.service, props.reservationTime, props.memberClassTickets)
        console.log('mounted select classTicket', defaultTicket)
        selectedClassTicket.value = defaultTicket
        loading.value = false
      }, 500)
    })

    return { selectedClassTicket, loading, classTicketOptions }
  },
})

</script>

<style lang="postcss" scoped>
.select-service-classTicket {
    @apply bg-[#F5F7FA] p-[20px] rounded-[8px];
}
.title {
    @apply text-primary-100 font-bold text-[18px] pb-[8px];
}

::v-deep .el-input {
  @apply w-full;
}

.service-name {
    @apply text-gray-60;
}
</style>
