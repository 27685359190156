<template>
  <div class="reservation-create">
    <!-- <section class="card-container wrapper"> -->
    <div
      class="font-medium text-xl text-gray-100 cursor-pointer flex items-center"
      @click="router.go(-1)"
    >
      <span class="material-icons cursor-pointer" style="margin-right: 9px">chevron_left</span>
      新增預約
    </div>
    <div class="side-highlight-container">
      <div v-if="!isReady" v-loading="!isReady" style="height: 200px;" />
      <div v-if="isReady">
        <MemberInfo
          :config="configData"
          :reservation="reservation"
          @ref="getRef($event, 'memberInfo')"
          @update="dataUpdate($event, 'memberData')"
          @memberChange="memberChange"
        />
        <OtherUser
          v-if="peopleCount > 1"
          :peopleCount="peopleCount"
          @update="dataUpdate($event, 'otherUserData')"
        />
      </div>
    </div>

    <div class="side-highlight-container">
      <ServiceInfo
        :config="configData"
        :reservation="reservation"
        :peopleCount="peopleCount"
        @ref="getRef($event, 'serviceInfo')"
        @update="dataUpdate($event, 'mainServiceData')"
        @serviceChange="serviceChange"
        @subServiceChange="subServiceChange"
        @serviceUnitChange="serviceUnitChange"
      />
      <div v-if="canOrderOtherService">
        <div
          v-if="!mainServiceData.date || !mainServiceData.time"
          class="text-sub text-gray-100 mb-[16px]"
        >
          請先選擇預約日期與時段
        </div>
        <OtherService
          v-if="mainServiceData.date && mainServiceData.time"
          :date="mainServiceData.date"
          :config="configData"
          :peopleCount="peopleCount"
          :mainServiceData="mainServiceData"
          @ref="getRef($event, 'otherService')"
          @update="dataUpdate($event, 'otherServiceData')"
        />
      </div>
    </div>

    <!-- ANCHOR 付款 -->
    <div
      v-if="showPaymentSection | showServiceClassTicket"
      data-testid="payment-section"
      class="side-highlight-container flex flex-col"
      style="gap: 24px"
    >
      <p class="block-title">預約付款資訊</p>
      <p v-show="!fillBasicData" class="text-sub text-gray-60">
        請先填寫預約資訊
      </p>
      <ServiceClassTicket
        v-if="enabledBlocks.serviceClassTicket && showServiceClassTicket && fillBasicData"
        :mainServiceData.sync="mainServiceData"
        :otherServiceData.sync="otherServiceData"
        @ref="getRef($event, 'classTicket')"
      />

      <ReservationDiscount
        v-if="fillBasicData && configData.checkout.enableCashbackDeduction && useFeatures.cashbackDiscount && chargeType === 'checkout'"
        data-testid="discount-block"
        @change="calculateCheckoutPrice"
      />

      <!-- ANCHOR 訂金 -->
      <Deposit
        v-if="showDepositBlock && enabledBlocks.deposit"
        data-testid="deposit-block"
        :memberData="memberData"
        :orderDeposit="orderDeposit"
        :model.sync="depositPayment"
        :depositConfig="depositConfig"
        :depositPaymentConfig="depositPaymentConfig"
        :isUseClassTicket="isUsingClassTicket"
      />

      <!-- 結帳付款 -->
      <Payment
        v-show="showCheckoutBlock && fillBasicData"
        data-testid="checkout-block"
        :orderCheckout="orderCheckout"
        :memberData="memberData"
        :model.sync="selectCheckoutPayment"
      />
    </div>

    <!-- ANCHOR 發票 -->
    <InvoiceBlock
      v-if="showInvoiceBlock && selectCheckoutPayment && !['wallet', 'free'].includes(selectCheckoutPayment)"
      data-testid="invoice-block"
      :memberData="memberData"
      @ref="getRef($event, 'invoice')"
      @update="dataUpdate($event, 'invoiceData')"
    />

    <div class="side-highlight-container">
      <Note
        :data="compactOrder"
        @ref="getRef($event, 'note')"
        @update="dataUpdate($event, 'noteData')"
      />
    </div>

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.go(-1)"
      @confirm="createReservationOrder"
    />
    <!-- </section> -->
  </div>
</template>

<script>
import reservationMixin from '@/mixin/reservation'
import ReservationDiscount from './components/ReservationDiscount.vue'
import { CreateReservationOrder, SmartCreateReservationOrder, FindReservation } from '@/api/reservation'
import MemberInfo from './components/MemberInfo.vue'
import OtherUser from './components/OtherUser.vue'
import ServiceInfo from './components/ServiceInfo.vue'
import InvoiceBlock from './components/InvoiceBlock.vue'
import OtherService from './components/OtherService.vue'
import ServiceClassTicket from './components/ServiceClassTicket.vue'
import Deposit from './components/Deposit.vue'
import Note from './components/Note.vue'
import formUtils from '@/utils/form'
import { get, isEmpty, filter, omitBy, isUndefined, some, cloneDeep } from 'lodash'
import debounce from 'lodash.debounce'
import { defineComponent, onMounted, ref, computed, onBeforeMount, onBeforeUnmount, watch, nextTick } from 'vue'
import { useReservation } from '@/use/useReservation'
import { useRouter, useRoute } from 'vue-router/composables'
import { mapGetters, mapActions } from 'vuex'
import Payment from './components/Payment.vue'
import createReservationStore from '@/store/reservationCreate/reservationCreate'
import storeMutations from '@/store/reservationCreate/mutations'
import store from '@/store'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { usePermissions } from '@/use/permissions'
import { GetClassTicketRecord2 } from '@/api/classTicket'

export default defineComponent({
  name: 'ReservationCreate',
  components: {
    MemberInfo,
    ServiceInfo,
    OtherService,
    ServiceClassTicket,
    // SelectServiceClassTicket,
    Deposit,
    Note,
    OtherUser,
    InvoiceBlock,
    Payment,
    ReservationDiscount,
  },
  mixins: [reservationMixin],

  setup (props, { emit }) {
    const { configData, getAllConfig, enabledBlocks, showInvoiceBlock, showCheckoutBlock, useFeatures, chargeType } =
      useReservation()
    const { fetchAll } = useFetch()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const router = useRouter()
    const route = useRoute()
    const isReady = computed(() => get(store.state, `${storeModuleName}.ready`))
    const storeModuleName = 'reservationCreate'
    const reservation = ref({})
    const findReservation = async (id) => {
      const res = await FindReservation({
        shopId: shopId.value,
        id,
      })
      if (res) {
        reservation.value = res
      }
    }
    onBeforeMount(async () => {
      store.registerModule(storeModuleName, createReservationStore)
      await nextTick()
      await getAllConfig()
      store.commit(`${storeModuleName}/${storeMutations.setStateData}`, {
        key: 'configData',
        value: cloneDeep(configData),
      })
      store.commit(`${storeModuleName}/${storeMutations.setStateData}`, {
        key: 'ready',
        value: true,
      })
    })
    onBeforeUnmount(() => {
      store.unregisterModule('reservationCreate')
    })
    onMounted(async () => {
      const reservationId = get(route.query, 'id')
      if (reservationId) {
        await findReservation(reservationId)
      }
    })
    return {
      isReady,
      configData,
      enabledBlocks,
      router,
      showInvoiceBlock,
      showCheckoutBlock,
      useFeatures,
      chargeType,
      storeModuleName,
      checkAction,
      fetchAll,
      shopId,
      reservation,
    }
  },
  computed: {
    isUsingClassTicket () {
      if (get(this.mainServiceData, 'classTicket')) return true
      if (!isEmpty(filter(this.otherServiceData, (service) => service.classTicket))) return true
      return false
    },
    showPaymentSection () {
      if (this.chargeType === 'none') return false
      else if (this.chargeType === 'deposit') {
        const needDeposit = this.useFeatures.deposit && get(this.configData, 'deposit.enable')
        return needDeposit
      } else if (this.chargeType === 'checkout') {
        const needCheckoutPayment = this.useFeatures.checkoutPayment
        if (!this.mainServiceData.service) {
          return needCheckoutPayment
        } else {
          if (this.mainServiceData.classTicket || some(this.otherServiceData, (item) => item.classTicket)) return true
          if (this.fillBasicData && this.orderCheckout.price <= 0) return false
          return needCheckoutPayment
        }
      }

      return false
    },

  },

  watch: {
    async form (form) {
      console.log('watch', form)
    },
    compactReservation: {
      async handler (val) {
        if (!this.fillBasicData) return
        // if (this.configData.checkout.enableCashbackDeduction || !this.useFeatures.cashbackDiscount) return
        if (!this.configData.checkout.enableCashbackDeduction) return
        store.dispatch('reservationCreate/checkOrderCashbackLimit', {
          shopId: this.shopId,
          memberId: get(this.memberData, 'member.id', undefined),
          reservations: this.compactReservation,
        })
      },
      deep: true,
    },
  },
  async mounted () {
    await this.getShopReservationConfig({ shopId: this.shopId })
  },
  methods: {
    ...mapActions('shop', ['getShopReservationConfig']),
    get,
    async memberChange (member) {
      if (!member?.id) {
        this.$store.commit(`${this.storeModuleName}/${storeMutations.uppdateMemberClassTickets}`, [])
        return
      }
      if (this.checkAction('admin.classTicketRecord.find')) {
        await this.fetchAll(GetClassTicketRecord2, { shopId: this.shopId, MemberId: member.id }, (res) => {
          this.$store.commit(`${this.storeModuleName}/${storeMutations.uppdateMemberClassTickets}`, res)
        })
      }
      // this.mainServiceData.classTicket = null
    },
    serviceChange () {
      delete this.mainServiceData.classTicket
    },
    subServiceChange () {
    },
    serviceUnitChange () {
      delete this.mainServiceData.classTicket
    },
    async dataUpdate (data, type) {
      if (type === 'memberData') {
        this.memberData = data
      } else if (type === 'mainServiceData') {
        if (get(this.mainServiceData, 'time') !== data.time) this.otherServiceData = []
        this.mainServiceData = data
      } else if (type === 'otherServiceData') this.$set(this.otherServiceData, 0, data)
      else if (type === 'otherUserData') this.otherUserData = data
      else if (type === 'noteData') this.noteData = data
      else if (type === 'invoiceData') this.invoiceData = data
    },

    createReservationOrder: debounce(
      async function () {
        if (this.loading) return
        this.loading = true

        const chargeType = get(this.configData, 'reservation.chargeType')

        for (const form of Object.keys(this.form)) {
          if (this.form[form]) {
            if (!(await formUtils.checkForm(this.form[form]))) {
              this.loading = false
              return
            }
          }
        }
        const formsPass = await store.dispatch(`${this.storeModuleName}/validateForms`)
        if (!formsPass) {
          this.loading = false
          return
        }

        const isMember = this.memberData.isMember
        const memberName = isMember
          ? get(this.memberData, 'member.UserInfo.name')
          : this.memberData.name
        const memberPhone = isMember
          ? get(this.memberData, 'member.UserInfo.phone')
          : this.memberData.phone

        const payload = {
          shopId: this.shopId,
          reservations: this.compactReservation,
          userType: isMember ? 'member' : 'guest',
          memberId: isMember ? get(this.memberData, 'member.id', undefined) : undefined,
          userName: memberName,
          userPhone: memberPhone,
          peopleCount: this.peopleCount,
          userComment: get(this.noteData, 'comment') || undefined,
          adminComment: get(this.noteData, 'remark') || undefined,
        }

        // 預約結帳模式
        if (chargeType === 'checkout') {
          let checkoutPayment = this.selectCheckoutPayment
          let checkoutPaymentComment = null
          if (this.selectCheckoutPayment.includes('offline')) {
            checkoutPayment = 'offline'
            checkoutPaymentComment = this.selectCheckoutPayment
          }
          payload.checkoutPrice = this.orderCheckout.price
          payload.checkoutPaidType = checkoutPayment
          payload.checkoutPaidTypeComment = checkoutPaymentComment || undefined
          const invoiceType = get(this.invoiceData, 'invoiceType')

          const invoiceEnable = get(this.configData, 'checkoutInvoice.enable')
          if (this.orderCheckout.price > 0) {
            payload.checkoutInvoiceInfo = invoiceEnable && this.selectCheckoutPayment !== 'wallet' ? omitBy(
              {
                buyerType: invoiceType,
                buyerEmail: get(this.invoiceData, 'email'),
                buyerName: invoiceType === 'B' ? get(this.invoiceData, 'companyTitle') : undefined,
                buyerIdentifier: invoiceType === 'B' ? get(this.invoiceData, 'taxId') : undefined,
              },
              isUndefined,
            ) : undefined

            if (get(this.configData, 'checkout.enableCashbackDeduction')) {
              payload.cashbackDeductionAmount = get(store.state, 'reservationCreate.orderDiscount.cashback') || undefined
            }
          }
        } else if (chargeType === 'deposit') {
          // 預約訂金模式
          let depositPayment = this.depositPayment
          let depositComment = null
          if (this.depositPayment.includes('offline')) {
            depositPayment = 'offline'
            depositComment = this.depositPayment
          }
          payload.depositPrice = this.showDepositBlock ? this.orderDeposit.price : undefined
          payload.depositPaidType = this.showDepositBlock ? depositPayment : undefined
          payload.depositPaidTypeComment = depositComment || undefined
        }

        const [, err] = await SmartCreateReservationOrder(payload)
        if (err) {
          this.loading = false
          this.$message.error(err)
          return
        }

        this.$message.success('預約成功')
        this.$router.go(-1)
        this.loading = false
      },
      300,
      { leading: false, trailing: true },
    ),
  },
})
</script>

<style scoped lang="postcss">
.reservation-create {
  @apply flex flex-col gap-[30px];
}
.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}

.block-title {
  @apply font-bold text-primary-100 text-[18px];
}
</style>
